import algoliasearch from 'algoliasearch'
import { Link } from 'gatsby'
import React from 'react'
import {
  Configure,
  connectHits,
  connectPagination,
  connectSearchBox,
  InstantSearch,
  Pagination
} from 'react-instantsearch-dom'
import { useSiteMetaData } from '../hooks/useSiteMetaData'
import { joinSpace } from '../libs/utils'
import DefaultLayout from '../layouts/DefaultLayout'
import HeadMeta from '../components/organisms/HeadMeta'
import * as styles from '../css/pages/search.module.styl'
import * as stylesLinkButtonPager from '../components/atoms/buttons/link-button-pager.module.styl'
import * as stylesPager from '../components/molecules/pager/pager.module.styl'

const Search: React.FC = () => {
  const site$ = useSiteMetaData()
  const { ALGOLIA_APP_ID, ALGOLIA_API_KEY, ALGOLIA_INDEX_NAME } = site$.config

  const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY)

  const CustomSearchBox = connectSearchBox(({ currentRefinement, refine }) => {
    return (
      <form
        noValidate={true}
        action=""
        role="search"
        onSubmit={(event) => event.preventDefault()}
      >
        <div className={joinSpace([styles.searchBox])}>
          <input
            className={joinSpace([styles.searchBoxInput])}
            type="search"
            autoFocus={true}
            value={currentRefinement}
            onChange={(event) => refine(event.currentTarget.value)}
            placeholder="Search here..."
          />{' '}
          <i
            className={joinSpace(['fas', 'fa-search', styles.searchBoxIcon])}
          />{' '}
        </div>
      </form>
    )
  })

  const CustomHits = connectHits<{
    objectID: string
    title: string
    uri: string
    excerpt: string
    content: string
  }>(({ hits }) => {
    // TODO: 検索結果がない場合
    return (
      <div className={joinSpace([styles.searchItems])}>
        {hits.length === 0 ? (
          <p className={joinSpace(['mb-0'])}>
            {'お探しのワードでは見つかりませんでした。'}
          </p>
        ) : (
          hits.map((hit) => (
            <div className={joinSpace([styles.searchItem])} key={hit.objectID}>
              <Link to={hit.uri}>{hit.title}</Link>
              <div
                className={joinSpace([
                  styles.searchContext,
                  'typography-sub-copy'
                ])}
                dangerouslySetInnerHTML={{ __html: hit.excerpt }}
              />
            </div>
          ))
        )}
      </div>
    )
  })

  const CustomPagination = connectPagination(
    ({ currentRefinement, nbPages, createURL, refine }) => {
      const LinkButton: React.FC<{ page: number; name: string | number }> = ({
        page,
        name
      }) => (
        <a
          href={createURL(page)}
          className={joinSpace([stylesLinkButtonPager.linkButton])}
          onClick={(event) => {
            event.preventDefault()
            refine(page)
            window.scrollTo(0, 0)
          }}
        >
          {name}
        </a>
      )

      const PagerButton: React.FC<{
        page: number
        current: number
      }> = ({ page, current }) => {
        if (page === current) {
          return (
            <span
              className={joinSpace([stylesLinkButtonPager.linkButton])}
              data-disabled="true"
            >
              {page}
            </span>
          )
        }

        return <LinkButton page={page} name={page} />
      }

      return (
        <div className={joinSpace([styles.searchPager])}>
          <ul className={joinSpace([stylesPager.pager])}>
            {currentRefinement > 1 && (
              <li className={joinSpace([stylesPager.pagerItem])}>
                <LinkButton page={currentRefinement - 1} name={'前へ'} />
              </li>
            )}

            {new Array(nbPages).fill(null).map((_, index) => {
              const page = index + 1

              return (
                <li
                  key={page}
                  className={joinSpace([
                    stylesPager.pagerItem,
                    stylesPager.pagerItemNumber
                  ])}
                >
                  <PagerButton page={page} current={currentRefinement} />
                </li>
              )
            })}

            {currentRefinement < nbPages && (
              <li className={joinSpace([stylesPager.pagerItem])}>
                <LinkButton page={currentRefinement + 1} name={'次へ'} />
              </li>
            )}
          </ul>
        </div>
      )
    }
  )

  return (
    <DefaultLayout>
      {' '}
      <HeadMeta title="検索" />{' '}
      <InstantSearch indexName={ALGOLIA_INDEX_NAME} searchClient={searchClient}>
        {' '}
        <Configure
          hitsPerPage={10}
          analytics={false}
          attributesToHighlight={['title', 'excerpt', 'content']}
        />
        <CustomSearchBox /> <CustomHits /> <CustomPagination />{' '}
      </InstantSearch>{' '}
    </DefaultLayout>
  )
}

export default Search
